<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <b-row class="match-height">
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </b-row>
    </template>
    <div

      class="col-md-12 mb-2"
    >
      <div style="gap:5px;">
        <router-link :to="{name : 'admin-export'}">
          <b-button
            variant="primary"
            class="mr-50 col-md-2"
          >
            {{ $t('exportList') }}
          </b-button>
        </router-link>
        <b-button
          variant="secondary"
          class="mr-50 col-md-2 mt-1 mt-lg-0 mt-md-0"
          @click="printpdf()"
        >
          {{ $t('print') }}
        </b-button>
      </div>
    </div>

    <b-row class="match-height">
      <div class="row d-flex">
        <div class="col-md-7 col-lg-7 col-sm-12">
          <div>
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-1">
                # {{ $t('orderNumber') }} {{ items.itemnumber_text }} ({{ items.username }})
              </h4>
              <div class="d-flex align-items-center mb-1">
                <div class="w-25 mt-1">
                  <h4 class="font-weight-bolder mb-1">
                    {{ $t('status') }}
                  </h4>
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_out === 1"
                  class="w-50 badge badge-light-danger col-md-3"
                >
                  {{ $t('awaitingPickup') }}
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_out === 2"
                  class="w-50 badge badge-light-success col-md-3"
                >
                  {{ $t('receivedItem') }}
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_thaiout === 1"
                  class="w-50 badge badge-light-primary col-md-3"
                >
                  {{ $t('awaitingDeliveryTH') }}
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_thaiout === 2"
                  class="w-50 badge badge-light-success col-md-3"
                >
                  {{ $t('delivered') }}

                </div>
                <div
                  v-if="items.payNow === 3"
                  class="w-50 badge badge-light-secondary col-md-3"
                >
                  {{ $t('cancelled') }}
                </div>
                <p
                  v-if="items.payNow === 3"
                  style="color:red;"
                >
                  *{{ $t('canceledBy') }} {{ items.by_Cancel }}
                </p>
              </div>
              <h4 class="font-weight-bolder mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-map-pin"
                ><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle
                  cx="12"
                  cy="10"
                  r="3"
                /></svg>{{ $t('recipientAddress') }}
              </h4>

              <div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('firstName') + '-' + $t('lastName') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.address.fullname }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.address.address }} / {{ items.address.subdistrict }} / {{ items.address.district }} / {{ items.address.province_name }} / {{ items.address.postalcode }}
                    <!-- 177/263 หลักหก / เมืองปทุมธานี / ปทุมธานี / 12000 -->
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('mobileNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.address.tel }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('deliveryType') }}
                    </p>
                  </div>

                  <div class="w-50">
                    <span class="d-flex">{{ items.typedelivery.pro_name }} <p style="color:red;"> ({{ items.typedelivery.paydelivery }})</p></span>
                  </div>
                </div>
                <div

                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('remarks') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.remark ? items.remark: '-' }}
                  </div>
                </div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('sender') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.delivery.name }}
                  </div>
                </div>
                <div
                  v-if="items.delivery.id === 2"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ $t('receivedNotseeprice') }}
                  </div>
                </div>

                <div
                  v-if="items.delivery.id === 3"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.delivery.address }}
                  </div>
                </div>
                <div
                  v-if="items.delivery.id === 3"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('mobileNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.delivery.tel }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5 col-lg-5 col-sm-12">
          <validation-observer ref="simpleRules">
            <div>
              <div class="card p-2">
                <h4 class="font-weight-bolder mb-1">
                  {{ $t('exportInthai') }}
                </h4>

                <div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-cols-md="4"
                      >
                        <template v-slot:label>
                          {{ $t('exportTrackingNumber') }}
                        </template>
                        <b-form-input v-model="items.postnumber_th" />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group

                        label-cols="4"
                        label-cols-lg="4"
                        label-cols-md="4"
                      >
                        <template v-slot:label>
                          <span class="text-danger">*</span> {{ $t('shippingcosts') }}
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="parcel-code"
                          rules="required"
                        >
                          <b-input-group
                            append="฿"
                          >
                            <b-form-input
                              v-model="items.Shipping_costth"
                              :placeholder="0"
                              :state="errors.length > 0 ? false:null"
                              @input="checkShipping(items.Shipping_costth)"
                            />
                          </b-input-group>
                          <span class="text-danger">{{ errors[0] ? $t('entershippingcost') : '' }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-cols-md="4"
                      >
                        <template v-slot:label>
                          <span v-if="check_shipping === 1">{{ $t('saveMoremoney') }}</span>
                          <span v-if="check_shipping === 2">{{ $t('Refunds') }}</span>
                        </template>
                        <p class="mt-50">
                          {{ Shipping }} ฿
                        </p>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-cols-md="4"
                      >
                        <template v-slot:label>
                          {{ $t('remarks') }}
                        </template>
                        <b-form-textarea
                          id="textarea-default"
                          v-model="items.remark_th"
                          rows="2"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-cols-md="4"
                      >
                        <template v-slot:label>
                          <b style="color:red;">*</b> {{ $t('transactionEvidence') }}
                        </template>

                        <b-form-file
                          v-model="fileimg"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          @change="checkfile"
                        />

                        <img
                          v-if="fileimg_th"
                          :src="GetImg('paymentfile',fileimg_th)"
                          accept=".jpg, .png, .jpeg"
                          alt="รูปภาพ"
                          class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          @click="showPicture(fileimg_th)"
                        >
                        <vue-image-lightbox-carousel
                          ref="lightbox"
                          :show="showLightbox"
                          :images="images"
                          :show-caption="false"
                          @close="closeBox()"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-cols-md="4"
                      >
                        <template v-slot:label>
                          <b style="color:red;">*</b> {{ $t('exportDate') }}
                        </template>

                        <b-form-datepicker
                          v-model="items.out_thaiwarehouse"
                          locale="th"
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>

                  <div
                    v-if="items.payNow === 2"
                    class="d-flex align-items-center mb-1"
                  >
                    <b-button
                      variant="success"
                      class="mt-1 mr-50 col-md-6"
                      @click="submit()"
                    >
                      {{ $t('Exportrecord') }}
                    </b-button>
                    <b-button
                      variant="danger"
                      class="mt-1 mr-50 col-md-6"
                      @click="checkcancel(items)"
                    >
                      {{ $t('cancelOrder') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </validation-observer>
        </div>
      </div>

      <b-col lg="12">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              {{ $t('parcelCodeList') }}
            </h3>
          </div>

          <div class="table-container">
            <table
              class="styled-table mt-n25"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t('lotSequence') }}
                  </th>

                  <th>
                    {{ $t('parcelCode') }}
                  </th>

                  <th>
                    {{ $t('orderList') }}
                  </th>

                  <th>
                    {{ $t('arrivedThailand') }}
                  </th>

                  <th>
                    {{ $t('quantity') }}
                  </th>

                  <th>
                    {{ $t('weight') }}
                  </th>

                  <th>
                    {{ $t('width') }}
                  </th>

                  <th>
                    {{ $t('height') }}
                  </th>

                  <th>
                    {{ $t('length') }}
                  </th>

                  <th>
                    {{ $t('cubic') }}
                  </th>
                  <th>
                    {{ $t('arrange') }}
                  </th>

                  <th>
                    {{ $t('type') }}
                  </th>

                  <th>
                    {{ $t('serviceFee') }}
                  </th>

                  <th>
                    {{ $t('pricePerKilo') }}
                  </th>

                  <th>
                    {{ $t('pricePerCubic') }}
                  </th>
                </tr>
              </thead>
              <thead class="text-center">
                <tr style="background: #fff; color: black;">
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ $t('total') }}</b>
                  </th>
                  <th
                    class="text-center"
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.product_amount,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + (item.weight*item.product_amount),0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.width,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.height,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ Commas(postnumber.reduce((total,item) => total + item.long,0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff; color: black;"
                  >
                    <b>{{ CommasCue(postnumber.reduce((total,item) => total + (item.charge_cue*item.product_amount),0)) }}</b>
                  </th>
                  <th
                    scope="col"
                    style="background: #fff"
                  />
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    {{ sumall = Commas(postnumber.reduce((total,item) => total + (item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0)) }}
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_kilo === item.charge_per_save && item.discount ? item.amount_discount: 0) + (!item.discount && item.charge_per_kilo === item.charge_per_save ? item.charge_per_kilo : 0),0)) }}
                    <b />
                  </th>
                  <th
                    class="text-center"
                    style="background: #fff; color: black;"
                  >
                    {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_cue === item.charge_per_save && item.discount ? item.amount_discount: 0) + (!item.discount && item.charge_per_cue === item.charge_per_save ? item.charge_per_cue : 0),0)) }}
                    <b />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item,index) in postnumber"
                  :key="index"
                >
                  <td style="width: 150px">
                    <i
                      v-if="item.transportType === 1"
                      class="fas fa-truck-moving"
                    />
                    <i
                      v-if="item.transportType === 2"
                      class="fas fa-ship"
                    />

                    {{ item.lot_name }} / {{ item.lot_order }}

                  </td>
                  <td>{{ item.postnumber }}</td>
                  <td>
                    <router-link :to="{name : 'admin-order-detail', params: {id: item.order_id}}" target="_blank" v-if="item.order_id">
                        <span>{{ item.order_text ? `ORD-${item.order_text}`: '-' }}</span>
                      </router-link>
                      <span v-else>-</span>
                  </td>
                  <td>{{ item.out_thaiwarehouse }}</td>
                  <td>{{ Commas(item.product_amount) }}</td>
                  <td>{{ Commas(item.weight*item.product_amount) }}</td>
                  <td>{{ Commas(item.width) }}</td>
                  <td>{{ Commas(item.height) }}</td>
                  <td>{{ Commas(item.long) }}</td>
                  <td>{{ CommasCue(item.charge_cue*item.product_amount) }}</td>
                  <td>{{ item.collocate_wh_name ? item.collocate_wh_name: '-' }}</td>
                  <td style="width: 50px">
                    {{ item.product_type ? item.product_type.pro_initial: '' }}
                  </td>
                  <td>{{ Commas(item.product_inspection_fee + item.re_wrap_fee + item.shipping_fee + item.other + item.packaging_fee) }}</td>
                  <td>
                    <span v-if="item.discount && item.charge_per_kilo === item.charge_per_save">
                      {{ Commas(item.charge_per_kilo) }}<span class="text-danger">-{{ Commas(item.discount) }}% = {{ Commas(item.amount_discount) }}
                      </span>
                    </span>
                    <span v-else>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                    <!-- {{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->
                  </td>
                  <td>
                    <span v-if="item.discount && item.charge_per_cue === item.charge_per_save">
                      {{ Commas(item.charge_per_cue) }}<span class="text-danger">-{{ Commas(item.discount) }}% = {{ Commas(item.amount_discount) }}
                      </span>
                    </span>
                    <span v-else>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</span>
                    <!-- {{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }} -->

                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('total') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >
                    <b
                      v-if="items.coupon"
                    >
                      <b style="text-decoration: line-through; color: red;">{{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿</b>
                      {{ Commas((postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)- items.coupon.discount_coupon)) }} ฿

                      <!-- <b style="text-decoration: line-through; color: red;">{{ Commas(postnumber.reduce((total,item) => total + item.charge_per_save +item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)) }} ฿ </b>
                      {{ Commas((postnumber.reduce((total,item) => total + item.charge_per_save + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)- items.coupon.discount_coupon)) }} ฿ -->

                      <!--  - arritem.coupon.discount -->
                    </b>
                    <b
                      v-else
                    >
                      {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_save === item.charge_per_cue && item.discount ? item.amount_discount: 0)+ (item.charge_per_save === item.charge_per_kilo && item.discount ? item.amount_discount: 0)+
                        (!item.discount ? item.charge_per_save: 0) + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee ,0)) }} ฿
                    <!-- {{ Commas(postnumber.reduce((total,item) => total + item.charge_per_save + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)) }} ฿ -->
                    </b>
                    <!-- {{ postnumber.reduce((total,item) => total + item.charge_per_save,0) + sumall }} ฿ -->
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('discountCoupon') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >
                    <b
                      v-if="items.coupon"

                      style="color: red; "
                    >{{ Commas(items.coupon.discount_coupon) }} ฿</b>
                    <b
                      v-else
                    >0 ฿ </b>
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('thaiWarehouseFee') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >
                    {{ Commas(items.Service_fee) }} ฿
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    <small class="text-danger">{{ $t('estimatedCostNote') }}</small>   {{ $t('domesticDeliveryFee') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right"
                  >

                    <b
                      v-if="items.promotion"
                    >
                      <b
                        style="text-decoration: line-through; color: red;"
                      >{{ Commas(items.promotion.old_delivery) }} ฿</b>
                      <b> {{ Commas(items.promotion.discount_pro) }} ฿</b>
                    </b>

                    <b
                      v-else
                    >{{ Commas(items.Shipping_cost) }} ฿ </b>
                    <!-- <span>
                      55 ฿
                    </span> -->
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="12"
                    class="text-right font-weight-bolder"
                  >
                    {{ $t('netAmount') }}
                  </td>

                  <td
                    colspan="3"
                    class="text-right h3"
                  >
                    {{ Commas(items.payment) }} ฿
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <div class="row d-flex">
        <div class="col-md-6 col-lg-6 col-sm-12">
          <div>
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-1">
                {{ $t('Payment') }}
              </h4>

              <div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('orderNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.itemnumber_text }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('customerCode') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.username }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('totalPaid') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ Commas(items.payment)  }} ฿
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('paymentType') }}
                    </p>
                  </div>

                  <div class="w-50 badge badge-light-primary col-md-3">
                    {{ $t('useSystemBalance') }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('status') }}
                    </p>
                  </div>

                  <div
                    v-if="items.payNow === 2 && items.order_out === 1"
                    class="w-50 badge badge-light-danger col-md-3"
                  >
                    {{ $t('awaitingPickup') }}
                  </div>
                  <div
                    v-if="items.payNow === 2 && items.order_out === 2"
                    class="w-50 badge badge-light-success col-md-3"
                  >
                    {{ $t('receivedItem') }}
                  </div>
                  <div
                    v-if="items.payNow === 2 && items.order_thaiout === 1"
                    class="w-50 badge badge-light-primary col-md-3"
                  >
                    {{ $t('awaitingDeliveryTH') }}
                  </div>
                  <div
                    v-if="items.payNow === 2 && items.order_thaiout === 2"
                    class="w-50 badge badge-light-success col-md-3"
                  >
                    {{ $t('delivered') }}

                  </div>
                  <div
                    v-if="items.payNow === 3"
                    class="w-50 badge badge-light-secondary col-md-3"
                  >
                    {{ $t('cancelled') }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('remarks') }}
                    </p>
                  </div>

                  <div class="w-50">
                    <p>{{ items.remark }}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="card">
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between mb-2">
                <h4 class="font-weight-bolder">
                  {{ $t('relatedAmountList') }}
                </h4>
              </div>

              <table class="styled-table mt-n25">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t('transactionDate') }}
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                    >
                      {{ $t('details') }}
                    </th>
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr
                    v-for="(arritem,index) in record"
                    :key="index"
                    style="background: #fff; color: black;"
                  >
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      {{ time(arritem.created_at) }}
                      <!-- 09 ตุลาคม 2023 13:16:12 -->
                    </th>
                    <th
                      class="text-left"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      {{ arritem.detail }}
                      <!-- รายการชำระค่านำเข้า PAY-10727 -->
                    </th>
                    <th
                      class="text-right"
                      scope="col"
                      style="background: #fff; color: black;"
                    >
                      <span :style="getAmountStyle(arritem.type)">
                        {{ getAmountPrefix(arritem.type) }} {{ Commas(arritem.amount.toFixed(2)) }} ฿
                      </span>
                      <!-- {{ arritem.amount }} -->
                      <!-- -9.00 ฿ -->
                    </th>
                  </tr>
                </thead>
              </table>
              <div class="d-flex align-items-center justify-content-start p-2" />
            </div>
          </div>
        </div>
      </div>

    </b-row>
  </b-overlay>

</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BButton, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BFormFile, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BFormInput,
    BFormGroup,
    // BPagination,
    BFormTextarea,
    // BLink,
    // BModal,
    // BTable,
    // BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormFile,
    BFormDatepicker,
    VueImageLightboxCarousel
  },
  data() {
    return {
      search: '',
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      fields: [
        // {
        //   key: 'itemnumber_text', label: 'หมายเลข', thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'username', label: 'ลูกค้า', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'fullname', label: 'ชื่อ-สกุล', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'created_at', label: 'วันที่สร้าง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'approvalDate', label: 'วันที่ส่งออก', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'deliverytype', label: 'ประเภทการจัดส่ง', sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        // },
        // {
        //   key: 'delivery', label: 'ผู้ส่ง', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'customerType', label: 'ผู้หาของ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'customer', label: 'ผู้แพ็ค', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'fileimg', label: 'หลักฐานการทำรายการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'payment', label: 'จำนวน', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'status', label: 'สถานะ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
        // {
        //   key: 'actions', label: 'จัดการ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        // },
      ],
      items: [
        // {
        //   id: 1,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/7a796ac0.jpg',
        //   customerType: 'บุคคลธรรมดา',
        //   amount: 100000,
        //   status: 'waiting',
        // },

        // {
        //   id: 2,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/72655903.jpg',
        //   customerType: 'นิติบุคคล',
        //   amount: 100000,
        //   status: 'success',
        // },
      ],
      pic: null,
      showOver: false,
      postnumber: [],
      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      status_thai: null,
      status_out: null,
      ID: this.$route.params.id,
      sumall: 0,
      record: [],
      postnumber_th: null,
      Shipping_costth: 0,
      remark_th: null,
      fileimg: null,
      filename: null,
      paymentout: null,
      check_shipping: 1,
      Shipping: 0,
      fileimg_th: null,
      required,
      showLightbox: false,
      images: []
    }
  },
  mounted() {
    this.getData()
    this.getbalance()
    // this.GetOrderAll(0)
  },
  methods: {
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
    },
    // eslint-disable-next-line camelcase
    getData() {
      this.showOver = true
      const params = {
        id: this.ID,
      }
      this.$http.get('/Paymentuser/ExportOne', { params })
        .then(response => {
          this.items = response.data
          if (this.items.fileimg_th) {
            this.fileimg_th = this.items.fileimg_th
          }

          if (this.items.Shipping_cost > this.items.Shipping_costth) {
            this.Shipping = this.items.Shipping_cost - this.items.Shipping_costth
            this.check_shipping = 2
          }
          if (this.items.Shipping_cost < this.items.Shipping_costth) {
            this.Shipping = this.items.Shipping_costth - this.items.Shipping_cost
            this.check_shipping = 1
          }
          // this.items.out_thaiwarehouse = moment(this.items.out_thaiwarehouse).format('YYYY-MM-DD')
          // this.Shipping = this.items.Shipping_cost
          this.getPostnumber(this.items)
          console.log(this.items)
          // console.log(response.data)
          // this.items = response.data.data
          // this.totalRows = this.items.length
          // totalRows
          this.showOver = false
        })

      // console.log(this.items)
      // const params = {
      //   page: this.currentPage,
      //   perPage: this.perPage,
      // }
      // // console.log(params)
      // this.$http
      //   .get('/Paymentuser/getExport', params)
      //   .then(response => {
      //     console.log(response)
      //     this.items = response.data.data
      //     // this.totalRows = this.items.length
      //     // this.totalRows = response.data.total
      //   })
        .catch(error => console.log(error))
    },
    getPostnumber(item) {
      const params = {
        data: item.postnumber_id,
      }

      this.$http.get('/StatusPayment/getPostnumber', { params })
        .then(response => {
          this.postnumber = response.data
          // console.log(this.postnumber)
        })
        .catch(error => console.log(error))
    },
    async getbalance() {
      const params = {
        id: this.ID,
      }
      this.$http.get('/StatusPayment/getBalance', { params })
        .then(response => {
          this.record = response.data
        })
        .catch(error => console.log(error))
    },
    submit() {
      if (this.fileimg_th === null) {
        this.Alert(false, this.$t('key-262'))
      } else if (this.items.out_thaiwarehouse == null) {
        this.Alert(false, this.$t('key-263'))
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$bvModal.msgBoxConfirm(this.$t('key-264'), {
              title: this.$t('key-13'),
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }).then(result => {
              if (result) {
                const time = this.time(this.items.out_thaiwarehouse)
                let filteredRecords = []
                if (this.record) {
                  filteredRecords = this.record.find(ele => ele.type === 17 || ele.type === 5)
                }
                const obj = {
                  id: this.ID,
                  idrecord: null,
                  uid: this.items.uid,
                  order_out: 0,
                  order_thaiout: 0,
                  postnumber_th: this.items.postnumber_th,
                  check_shipping: this.check_shipping,
                  Shipping_costth: this.items.Shipping_costth,
                  Shipping: this.Shipping,
                  remark_th: this.items.remark_th,
                  fileimg_th: this.fileimg_th,
                  paymentout: time,
                  itemnumber_text: this.items.itemnumber_text,
                  itemnumber: this.items.itemnumber,
                  username: this.items.username,
                }
                if (this.items.order_out === 1 || this.items.order_out === 2) {
                  obj.order_out = 2
                }
                if (this.items.order_thaiout === 1 || this.items.order_thaiout === 2) {
                  obj.order_thaiout = 2
                }
                if (filteredRecords) {
                  // eslint-disable-next-line no-underscore-dangle
                  obj.idrecord = filteredRecords._id
                }
                this.$http.post('/Paymentuser/updateExport', obj)
                  .then(() => {
                    this.Success(this.$t('key-265'))
                    this.getData()
                    this.getbalance()
                  })
                  .catch(error => {
                    this.SwalError(error)
                  })
              }
            })
          }
        })
      }

      // console.log('บันทึก')
    },
    checkShipping(value) {
      // eslint-disable-next-line eqeqeq
      if (value >= this.items.Shipping_cost) {
        this.Shipping = this.items.Shipping_costth - this.items.Shipping_cost
        this.check_shipping = 1
      }
      if (value < this.items.Shipping_cost) {
        this.Shipping = this.items.Shipping_cost - this.items.Shipping_costth
        this.check_shipping = 2
      }

    //   if (this.Shipping >= value) {
    //     this.Shipping = -(this.items.Shipping_cost - value)
    //     this.check_shipping = 1
    //   }
    //   if (this.Shipping < value) {
    //     this.Shipping = this.items.Shipping_cost - value
    //     this.check_shipping = 2
    //   }
    },
    checkcancel(data) {
      this.$bvModal.msgBoxConfirm(this.$t('cancelOrder') + data.itemnumber_text + this.$t('key-266'), {
        title: this.$t('key-267'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: data._id,
              uid: data.uid,
            }

            this.$http.post('/StatusPayment/Cancelpay', params)
              .then(() => {
                this.Success(this.$t('key-268'))
                this.getData()
                this.getbalance()
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
                // this.SwalError(error)
              })
          }
        })
      console.log(data)
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('paymentfile', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    printpdf() {
      try {
        window.open(`https://api.p2cargo.com/api/print/ExportProductsAdminPdf/${this.ID}`)
      } catch (e) {
        console.log(e)
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    checkfile(event) {
      this.fileimg = event.target.files
      this.fileimg_th = null
      const reader = new FileReader()
      const filepath = this.fileimg[0]
      reader.onload = e => {
        // console.log(e.target.result)
        this.fileimg_th = e.target.result
        // e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16].includes(amount)) {
          return { color: 'green' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'red' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16].includes(value)) {
          return '+'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '-'
        }
      }
      return ''
    },
    GetOrderAll(type) {
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
      }

      this.$http
        .get('/Paymentuser/getExport', { params })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-219')}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

      <style lang="scss" scoped></style>
